<template>
  <TunnelLayout>
    <template #form>
      <div class="flex flex-col">
        <div class="my-8">
          <div class="mb-4 flex flex-col">
            <label class="font-bold text-2xl text-promy-blue-300 sm:text-base"
              >Combien y a-t-il de pièces ?
            </label>
            <span class="text-promy-gray-250 font-dosis"
              >hors cuisine, salle de bain et WC</span
            >
          </div>
          <div>
            <validation-provider
              class="relative flex flex-col"
              rules="required"
              v-slot="{ errors }"
            >
              <div
                class="grid gap-2"
                style="grid-template-columns: repeat(auto-fill, 120px)"
              >
                <selectable-button
                  v-for="(piece, index) in pieces"
                  :key="piece + index"
                  v-model="form.informations.pieces"
                  :option-name="piece"
                />
              </div>
              <span
                v-show="errors[0]"
                class="mt-1 text-xs text-promy-red-400"
                >{{ errors[0] }}</span
              >
            </validation-provider>
          </div>
        </div>
        <div>
          <div class="mb-4 flex flex-col mt-8">
            <label class="font-bold text-2xl text-promy-blue-300 sm:text-base"
              >Quel est l’état de votre bien ?
            </label>
          </div>

          <div>
            <validation-provider
              class="relative flex flex-col space-y-2"
              rules="required"
              v-slot="{ errors }"
            >
              <div
                class="grid gap-2 grid-cols-3 xl:grid-cols-2 lg:grid-cols-2 sm:grid-cols-1 space-right"
              >
                <selectable-button
                  v-for="(etat, index) in etatsBien"
                  :key="etat + index"
                  v-model="form.informations.etat_bien"
                  :option-name="etat"
                />
              </div>
              <span v-show="errors[0]" class="text-xs text-promy-red-400">{{
                errors[0]
              }}</span>
              <pro-input
                v-if="form.informations.etat_bien === 'autre'"
                :placeholder="'Autre'"
                :rules="
                  form.informations.etat_bien === 'autre' ? 'required' : ''
                "
                v-model="form.informations.other_etat_bien"
              />
            </validation-provider>
          </div>
        </div>
      </div>
    </template>
    <template #buttons> <Buttons :form="form" :goNext="invalid" /></template>

    <template slot="right-side">
      <Map :showBtnParcelle="false" :form="form" :cadastre="cadastre" />
    </template>
  </TunnelLayout>
</template>
<script>
import Buttons from './components/Buttons.vue'
import Map from './components/Map.vue'

export default {
  components: {
    Buttons,
    Map,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    cadastre: {
      type: Object,
      require: true,
    },
    invalid: Boolean,
  },
  data() {
    return {
      pieces: [
        '1 pièce',
        '2 pièces',
        '3 pièces',
        '4 pièces',
        '5 pièces',
        '6+ pièces',
      ],
      etatsBien: [
        'Refait à neuf',
        'Habitable en l’état',
        'À raffraichir',
        'Travaux importants',
        'autre',
      ],
    }
  },
}
</script>

<style scoped>
.space-right > button {
  @apply mr-2 mb-2;
}
</style>
